import React from "react";
import styled from "styled-components";
import PageContainer from "../components/PageContainer";
import Section from "../components/Section";

import the_three_billy from "../images/book_covers/the_three_billy.jpg";
import all_ears_all_eyes from "../images/book_covers/all_ears_all_eyes.jpg";
import shoe_dog from "../images/book_covers/shoe_dog.jpg";
import all_the_water from "../images/book_covers/all_the_water.jpg";
import its_picture_day from "../images/book_covers/its_picture_day.jpg";
import Head from "../components/Head";

const BookContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0;

  @media (min-width: 769px) {
    flex-direction: row;
    max-width: 1000px;
    min-width: 860px;
    padding: 30px;
  }
`;

const BookCoverContainer = styled.div`
  width: 100%;
  text-align: center;

  & > img {
    width: 65%;
    object-fit: contain;
    margin: 3rem 0;

    @media (min-width: 769px) {
      width: 200px;
      margin: 0;
    }
  }

  @media (min-width: 769px) {
    width: 200px;
  }
`;

const BookTitle = styled.h3`
  font-family: "Arial Rounded MT Bold";
  color: #1c4891;
  margin: 0 !important;
  padding-bottom: 2rem;
`;

const BookAuthor = styled.span`
  font-size: 0.85rem;
`;

const BookPreviewParagraph = styled.p`
  font-size: 0.9rem !important;
  margin: 0.1rem 0 !important;
  line-height: 1.5;
`;

const BookInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const BookCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BookComment = styled.span`
  font-style: italic;
  padding-top: 0.5rem;
  font-size: 1rem;

  &::before {
    content: ${(props) => {
      console.log(props.leadingStar);
      return props.leadingStar ? "'⭑'" : "''";
    }};
    color: red;
    font-style: normal;
    padding-right: 0.5rem;
  }
`;

const BookCommentResource = styled.span`
  padding-left: 50%;
  font-size: 0.9rem;
  font-style: italic;
`;

const BookMetadata = styled.span`
  font-size: 0.7rem;
  font-weight: bold;
`;

const books = [
  {
    title: "The Three Billy Goats Gruff — The Full Story",
    cover: the_three_billy,
    author: "Richard Jackson",
    illustrator: "Katherine Tillotson",
    preview:
      "“<b>Ach!</b> Who is that tap-Tap-TAPPING on my bridge.”\nYou may know where this is going. The story of The Three Billy Goats Gruff and their encounter with that bully boy Troll has been told and told and told again. Yet who was the first silver tongued teller? Well…it seems that the brothers Gruff were rather proud of their troll-outsmarting abilities and of their storytelling prowess. And when a story is well told, the rest is history…or yet another story….This one!",
    metadata: [
      "Picture book, 40 pages, September 1, 2020",
      "Simon & Schuster/Atheneum, A Caitlyn Dlouhy Book",
      "ISBN 978-1-4814-1573-6",
    ],
  },
  {
    title: "All Ears All Eyes",
    author: "Richard Jackson",
    cover: all_ears_all_eyes,
    illustrator: "Katherine Tillotson",
    preview:
      "Shh…look…listen…to the sounds of the dark say Goodnight!\nWhat sails? What flies? Those…these, Down low, nearby, far off, up high.\nWho listens? Who looks? Who hears? Who sees?\nAn homage to the melodies of nighttime, to each critter that sings, hoots, or glows, All Ears, All Eyes takes us on a moonlit journey where the landscape shimmers with Fantasia-like beauty. Where if you look and listen, you might spy an owl, a deer, a chipmunk—or—what else!—before falling asleep.",
    comments: [
      {
        comment: "Dazzling",
        source: "Kirkus starred review",
      },
      {
        comment:
          "this is a celebration of the approaching darkness and all that happens in the night",
        source: "Booklist starred review",
      },
      {
        comment: "an immediate and vivid connection to the living world",
        source: "Publishers Weekly starred review",
      },
      {
        comment: "a soothing bedtime offering that’s perfect for reading aloud",
        source: "School Library Journal starred review",
      },
    ],
    metadata: [
      "Picture book, 40 pages, March 7, 2017",
      "Simon & Schuster/Atheneum, A Caitlyn Dlouhy Book",
      "ISBN-13: 9781481415712",
    ],
  },
  {
    title: "Shoe Dog",
    author: "Megan McDonald",
    cover: shoe_dog,
    illustrator: "Katherine Tillotson",
    preview:
      "Shoe Dog like to chew.\nAnd chew\nand chew.\n\nNot a boring old bone.\nNot a squeaky old toy.\nNot a smelly old sock.\n\nShoe Dog liked to chew...\nWell, guess!\n\nAnd that’s his trouble.",
    comments: [
      {
        comment: "Totally ebullient",
        source: "Kirkus starred review and Best Book 2014",
      },
      {
        leadingStar: false,
        comment:
          "This cool canine creation cavorts through an Escher-like home abstracted into furniture floating amid white space. Simple to relate to and easy to love.",
        source: "Booklist",
      },
    ],
    metadata: [
      "Picture book, 40 pages, March 2014",
      "Simon & Schuster/Atheneum, A Richard Jackson Book",
      "ISBN-10: 1-4169-79328",
      "ISBN-13: 978-1-4169-7932-6",
    ],
  },
  {
    title: "All the water in the world",
    author: "George Ella Lyon",
    cover: all_the_water,
    illustrator: "Katherine Tillotson",
    preview:
      "With lyrical language and evocative artwork this book traces the water cycle and celebrates the inter-connectedness of the life on our planet.",
    comments: [
      {
        comment:
          "Lots of picture books introduce young children to the water cycle, but few have such an infectious beat and eye-catching illustrations as this title, which begs to be read aloud.",
        source: "Booklist starred review",
      },
      {
        comment:
          "Filled with rhythm and sound, this offering begs to be read aloud.",
        source: "School Library Journal starred review",
      },
    ],
    metadata: [
      "Picture book, 40 pages, March 2011",
      "Simon & Schuster/Atheneum, A Richard Jackson Book",
      "ISBN-10: 1-4169-7130-0",
      "ISBN-13: 978-1-4169-7130-6",
    ],
  },
  {
    title: "It's Picture Day Today",
    author: "Megan McDonald",
    cover: its_picture_day,
    illustrator: "Katherine Tillotson",
    preview:
      "Button is late -\nlate, late, late.\nMissed the bus to school,\non this day of all days.\nEveryone else who makes up the class -\nFeathers, Strings,\nSprings, Rings,\nPom-Poms, Yarns,\nand Wheely Things -\nthey're all inside,\ngetting themselves together.\nGetting ready for the teacher,\nthe camera,\nthe wide-angle moment.\nBut wait...\nwait, wait, wait...\nButton's not the only one late!",
    comments: [],
    metadata: [
      "Picture book, 36 pages, June 2009",
      "Simon & Schuster/Atheneum, A Richard Jackson Book",
      "ISBN-10: 1-4169-2434-5",
      "ISBN-13: 978-1-4169-2434-0",
      "Ages: 4 - 8 Grades: P - 3",
    ],
  },
];

function IndexPage() {
  return (
    <>
      <Head title="Katherine Tillotson - Books" />
      <PageContainer reduceBackgroundOpacity>
        {books.map((book) => (
          <Section key={book.title}>
            <BookContainer>
              <BookCoverContainer>
                <img src={book.cover} alt={book.title} />
              </BookCoverContainer>
              <BookInfo>
                <BookTitle>{book.title}</BookTitle>
                <BookAuthor>{`by ${book.author}`}</BookAuthor>
                <BookAuthor>{`illustrated by ${book.illustrator}`}</BookAuthor>
                <br />
                {book.preview.split("\n").map((p, i) => (
                  <BookPreviewParagraph
                    dangerouslySetInnerHTML={{ __html: p }}
                    key={`${book.title}-preview-${i}`}
                  />
                ))}
                <br />
                {!!book.comments?.length &&
                  book.comments.map((comment, i) => (
                    <BookCommentContainer key={`${book.title}-comment-${i}`}>
                      <BookComment
                        leadingStar={
                          comment.leadingStar === undefined
                            ? true
                            : comment.leadingStar
                        }
                      >{`“${comment.comment}”`}</BookComment>
                      <BookCommentResource>{`— ${comment.source}`}</BookCommentResource>
                    </BookCommentContainer>
                  ))}
                <br />
                <>
                  {!!book.metadata?.length &&
                    book.metadata.map((data, i) => (
                      <BookMetadata key={`${book.title}-meta-${i}`}>
                        {data}
                      </BookMetadata>
                    ))}
                </>
              </BookInfo>
            </BookContainer>
          </Section>
        ))}
      </PageContainer>
    </>
  );
}

export default IndexPage;
