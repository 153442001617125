import styled from 'styled-components';

const Section = styled.section`
    color: #333;
    padding: 0 1.5em 3em 1.5em;
    max-width: 100vw;

    & p {
        font-size: 1.15rem;
        text-align: justify;
    }

    @media (min-width: 1000px) {
        max-width: 880px;
        margin: 0 auto;
    }
`

export default Section;